
import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import {Link} from "react-router-dom";

import Input from '../components/input/input';
import Inputpassword from '../components/input/input-password';

const Signup = ()=>{

    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatpassword, setRepeatpassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleCompanyChange = (event) => {
        setCompany(event.target.value);
    };

    const handleEmailChange = (event) => {
        const enteredEmail = event.target.value;
        setEmail(enteredEmail);
        validateEmail(enteredEmail);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        validatePasswords(event.target.value, repeatpassword);
    };

    const handleRepeatpasswordChange = (event) => {
        setRepeatpassword(event.target.value);
        validatePasswords(password, event.target.value);
    }

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
                setErrorMessage('Invalid email format');
            } else {
                setErrorMessage('');
            }
    };

    const validatePasswords = (password, repeatPassword) => {
        if (password !== repeatPassword) {
            setErrorMessage("Passwords do not match");
        } else {
            setErrorMessage("");
        }
    };

    const isFormValid =  
        name !== '' &&  
        company !== '' && 
        email !== '' && 
        password !== '' && 
        repeatpassword !== '' && 
        password === repeatpassword &&
        errorMessage === ''; 

    return(

        <section className = "main form-main">
            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "signup-content position-relative d-lg-flex align-items-center justify-content-end">
                            <div className = "signup-title">
                                <h1>
                                    Discover the KYC platform for global customer onboarding
                                </h1>
                                <p>
                                    Reduce verification costs by 46%
                                </p>
                                <p>
                                    Increase onboarding rate by 26% with minimum false positives
                                </p>
                                <p>
                                    Accessible in 230+ countries with support of 250+ document types
                                </p>
                            </div>
                            <div className = "sigup-login-form register-form">
                                <h1>
                                    Register Your Company
                                </h1>
                                <Input type = {'text'} value = {'name'} label = 'Name' onChange={handleNameChange}/>

                                <Input type = {'email'} value = {'email'} label = 'Email' onChange={handleEmailChange}/>

                                <Input type = {'text'} value = {'company'} label = 'Company Name' onChange={handleCompanyChange}/>

                                <Inputpassword type = 'password' value = {'password'} label = 'Password' onChange={handlePasswordChange}/>

                                <Inputpassword type = 'password' value = {'repeatpassword'} label = 'Repeat Password' onChange={handleRepeatpasswordChange}/>

                                {errorMessage && <p className="error-message mb-2 text-danger">{errorMessage}</p>}

                                <div className = "form-group">
                                    <p className = "mb-0">
                                        By submitting this form, you consent to allow us to store and process the personal information submitted above to provide you the content requested. Please review our <Link to = "#">Privacy Policy</Link>
                                    </p>
                                </div>

                                <div className = "form-group">
                                    <Button type = "submit" variant = "primary" disabled={!isFormValid}>
                                        Register
                                    </Button>
                                </div>

                                <div className = "form-group">
                                    <p className = "mb-0 text-center f-pra">
                                        <Link to = "/login">Sign in</Link>  If you already have an account
                                    </p>
                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        
    );
}

export default Signup;
