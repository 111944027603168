import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';

import Input from '../components/input/input';

const Forgotpassword = ()=>{

    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleEmailChange = (event) => {
        const enteredEmail = event.target.value;
        setEmail(enteredEmail);
        validateEmail(enteredEmail);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
                setErrorMessage('Invalid email format');
            } else {
                setErrorMessage('');
            }
    };

    const isFormValid = email !== '' &&
    errorMessage === '';

    return(

        <section className = "main form-main">
            <Container>
                <Row>
                    <Col xs = "12">

                    <div className = "signup-content position-relative d-flex align-items-center justify-content-center">
                            <div className = "sigup-login-form login-form">
                                <h1>
                                    Forgot Password?
                                </h1>

                                <div className = "form-group">
                                    <p className = "mb-0 text-center">
                                        Enter your registered email to get password recovery link
                                    </p>
                                </div>

                                <Input type = {'email'} value = {'email@example.com'} label = {'Email'} onChange={handleEmailChange}/>
                               
                               {errorMessage && <p className="error-message mb-2 text-danger">{errorMessage}</p>}

                                <div className = "form-group">
                                    <Button type = "submit" variant = "primary" disabled={!isFormValid}>
                                        Submit
                                    </Button>
                                </div>

                                <div className = "form-group">
                                    <p className = "text-center">
                                        <Link to = "/login">Back to Login</Link>.
                                    </p>
                                </div>
                            </div>
                    </div>

                    </Col>
                </Row>
            </Container>
        </section>
        
    );
}

export default Forgotpassword;
