
import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import UserFooter from "../../components/user-footer/user-footer";

import UploadPhoto from "../../images/user/passport-bg.png";

const UploadFile = () => {

    return (
        <section className = "main inner-page user-page">
            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "user-body text-start upload-id-passport">
                            <ProgressBar now={50} />
                            <div className = "inner-body text-start capture-photo">
                                
                                <div className = "choose-doc">
                                   
                                    <Row>
                                        <Col lg = {6}>
                                            <h1>
                                                Passport Photo
                                            </h1>
                                            <p>
                                                You can <span id = "upload"><Link to = "/upload-document">Capture</Link></span> a photo of the passport.
                                            </p>
                                        </Col>
                                        <Col lg = {6}>
                                            <div className = "Img-capture-section">
                                                <div className = "upload-photo camera-sec">
                                                    <img src = {UploadPhoto} alt = "upload-file"/>
                                                </div>
                                                
                                                <div className = "final-action">
                                                    <Row>
                                                        <Col md = {5}>
                                                            <Button variant = "primary-outline change-photo">
                                                                <input type = "file"/>
                                                                Change
                                                            </Button>
                                                        </Col>
                                                        <Col md = {7}>
                                                            <Link to = "/scan-face" className = "btn btn-primary">
                                                                Save & Continue
                                                            </Link>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                </div>

                            </div>
                        </div>
                        <UserFooter/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default UploadFile;