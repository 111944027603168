

import React from "react";

import "./userfooter.css";

const UserFooter = () => {
    return (
        <div className = "footer text-center text-uppercase">
            Powered by <span>Mwan Mobile</span>
        </div>
    )
}

export default UserFooter;