
import React from 'react';

import "./page-title.css";


const PageTitle = ({description}) => {
    return (
        <>
            <div className = "page-title">
                {description}
            </div>
        </>
    )
}

export default PageTitle;