
import React from "react";
import { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Collapse from 'react-bootstrap/Collapse';
import CustomSelect from "../select-box/selectbox";


import StatusComp from "../user-status/user-status";
import UserHeader from "../user-details/details-header";
import UserDetails from "../user-details/user-details";
import UserPhoto from "../user-details/user-photo";
import IdPhoto from "../user-details/id-photo";
import PageTitle from "../page-title/page-title";
import EmailStatus from "../user-status/email-status";

import Userimg from "../../images/dashboard/user.png";
import Idimg from "../../images/dashboard/id.png";
import detailBg from "../../images/dashboard/detail-db-icon.svg"

const ListingTable = () => {

    const [openRows, setOpenRows] = useState(-1);
    // const [activeRow, setActiveRow] = useState(null);

    // const toggleCollapse = (rowIndex) => {
    //     const isOpen = openRows.includes(rowIndex);
    //         if (isOpen) {
    //             setOpenRows(openRows.filter((row) => row !== rowIndex));
    //         } else {
    //             setOpenRows([...openRows, rowIndex]);
    //         }

    //         // setActiveRow(rowIndex);
    // };

    // const toggleRowActive = (rowIndex) => {
    //     return activeRow === rowIndex;
    // };

    const toggleCollapse = (rowIndex) => {
        if (openRows === rowIndex) {
          setOpenRows(-1); // Collapse the current row if it's clicked again
        } else {
          setOpenRows(rowIndex); // Open the clicked row
        }
    }

    const options = [
        { label: 'Monthly', value: 1 },
        { label: 'Yearly', value: 2 },
        { label: 'Weekly', value: 3 },
      ];
      

        const handleSelect = (option) => {
          console.log('Selected option:', option);
        };

    return (
        <>
            <Row className = "align-items-center">
                <Col md = {4}>
                    <PageTitle description={'KYC Listing'} mb-0/>
                </Col>
                <Col md = {8}>
                    <div className = "table-sort d-flex justify-content-md-end">
                        <input className = "form-control" placeholder = "Search..."/>
                        <div className = "sort-db">
                            <label>Sort By</label>
                            <CustomSelect options={options} onSelect={handleSelect} label = 'Sort by'/>
                        </div>
                    </div>
                </Col>
            </Row>

            <div className = "kyc-custom-table">
                <Table responsive className = "kyc-data-table mb-0">
                    <thead>
                        <tr>
                            <th>
                                ID
                            </th>
                            <th>
                                Email
                            </th>
                            <th>
                                Created AT
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                Details
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={openRows === 0 ? 'active' : ''}  onClick={() => toggleCollapse(0)} aria-controls="detail1" aria-expanded={openRows === 0}>
                            <td>
                                01
                            </td>
                            <td>
                                <EmailStatus className = "active" email={'lmartin@outlook.com'}/>
                            </td>
                            <td>
                                30 MAY 2023 - 02:00 PM
                            </td>
                            <td>
                                <div className = "status-check success">
                                    <StatusComp status= "success"/>
                                </div>
                            </td>
                            <td>
                                <div className="detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            
                            <td className = "hidden-row py-0" colSpan={5}>
                                <Collapse in={openRows === 0}>
                                    <div id="detail1">
                                        <Row className = "py-3">
                                            <Col xs = {8} md = {6} lg = {4} className = "mb-3 mb-lg-0">
                                                <UserHeader titleHeading={'KYC Info'}/>
                                                <div className = "kyc-info">
                                                    <UserDetails title = {'Name:'} titleDescription={'Ashfaq Ahmed'}/>
                                                    <UserDetails title = {'Email:'} titleDescription={'ashfaq.ahmed@mwanmobile.org'}/>
                                                    <UserDetails title = {'Born:'} titleDescription={'28/03/1980'}/>
                                                    <UserDetails title = {'ID:'} titleDescription={'3710256789643212'}/>
                                                    <UserDetails title = {'Expires:'} titleDescription={'15/09/2029'}/>
                                                    <UserDetails title = {'Address:'} titleDescription={'Bahira town phase 4, Plaza no 179'}/>
                                                </div>
                                            </Col>
                                            <Col xs = {4} md = {6} lg = {2} className = "mb-3 mb-lg-0">
                                                <UserHeader titleHeading={'Photo'}/>
                                                <UserPhoto photo= {Userimg}/>
                                            </Col>
                                            <Col xs = {6} md = {6} lg = {3} className = "mb-3 mb-md-0">
                                                <UserHeader titleHeading={'ID Card'} spantext={'Front'}/>
                                                <IdPhoto idphoto={Idimg}/>
                                            </Col>
                                            <Col xs = {6} md = {6} lg = {3} className = "mb-3 mb-md-0">
                                                <UserHeader titleHeading={'ID Card'} spantext={'Back'}/>
                                                <IdPhoto idphoto={Idimg}/>
                                            </Col>
                                        </Row>
                                    </div>
                                </Collapse>
                            </td>
                            
                        </tr>
                        <tr className={openRows === 1 ? 'active' : ''} onClick={() => toggleCollapse(1)} aria-controls="detail2" aria-expanded={openRows === 1}>
                            <td>
                                02
                            </td>
                            <td>
                                <EmailStatus className = "failed" email={'xgonzalez@aol.com'}/>
                            </td>
                            <td>
                                15 MAY 2023 - 03:18 PM
                            </td>
                            <td>
                                <div className = "status-check failed">
                                    <StatusComp status= "failed"/>
                                </div>
                            </td>
                            <td>
                                <div className="detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            
                            <td className = "hidden-row py-0" colSpan={5}>
                                <Collapse in={openRows === 1}>
                                    <div id="detail2">
                                        <Row className = "py-3">
                                            <Col xs = {8} md = {6} lg = {3} className = "mb-3 mb-lg-0">
                                                <UserHeader titleHeading={'KYC Info'}/>
                                                <div className = "kyc-info">
                                                    <UserDetails title = {'Name:'} titleDescription={'Ashfaq Ahmed'}/>
                                                    <UserDetails title = {'Email:'} titleDescription={'ashfaq.ahmed@mwanmobile.org'}/>
                                                    <UserDetails title = {'Born:'} titleDescription={'28/03/1980'}/>
                                                    <UserDetails title = {'ID:'} titleDescription={'3710256789643212'}/>
                                                    <UserDetails title = {'Expires:'} titleDescription={'15/09/2029'}/>
                                                    <UserDetails title = {'Address:'} titleDescription={'Bahira town phase 4, Plaza no 179'}/>
                                                </div>
                                            </Col>
                                            <Col xs = {4} md = {6} lg = {3} className = "mb-3 mb-lg-0">
                                                <UserHeader titleHeading={'Photo'}/>
                                                <UserPhoto photo= {Userimg}/>
                                            </Col>
                                            <Col xs = {6} md = {6} lg = {3} className = "mb-3 mb-md-0">
                                                <UserHeader titleHeading={'ID Card'} spantext={'Front'}/>
                                                <IdPhoto idphoto={Idimg}/>
                                            </Col>
                                            <Col xs = {6} md = {6} lg = {3} className = "mb-3 mb-md-0">
                                                <UserHeader titleHeading={'ID Card'} spantext={'Back'}/>
                                                <IdPhoto idphoto={Idimg}/>
                                            </Col>
                                        </Row>
                                    </div>
                                </Collapse>
                            </td>
                            
                        </tr>
                        <tr>
                            <td>
                                03
                            </td>
                            <td>
                                <EmailStatus className = "active" email={'pnguyen@gmail.com'}/>
                            </td>
                            <td>
                                11 MAY 2023 - 01:15 PM
                            </td>
                            <td>
                                <div className = "status-check success">
                                    <StatusComp status= "success"/>
                                </div>
                            </td>
                            <td>
                                <div className = "detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                04
                            </td>
                            <td>
                                <EmailStatus className = "inactive" email={'egarcia@icloud.com'}/>
                            </td>
                            <td>
                                12 MAY 2023 - 04:10PM
                            </td>
                            <td>
                                <div className = "status-check uncomplete">
                                    <StatusComp status= "uncomplete"/>
                                </div>
                            </td>
                            <td>
                                <div className = "detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                05
                            </td>
                            <td>
                                <EmailStatus className = "active" email={'mtorres@icloud.com'}/>
                            </td>
                            <td>
                                22 MAY 2023 - 03:20 PM
                            </td>
                            <td>
                                <div className = "status-check success">
                                    <StatusComp status= "success"/>
                                </div>
                            </td>
                            <td>
                                <div className = "detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                06
                            </td>
                            <td>
                                <EmailStatus className = "failed" email={'ghall@outlook.com'}/>
                            </td>
                            <td>
                                24 MAY 2023 - 06:18 PM
                            </td>
                            <td>
                                <div className = "status-check failed">
                                    <StatusComp status= "failed"/>
                                </div>
                            </td>
                            <td>
                                <div className = "detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                07
                            </td>
                            <td>
                                <EmailStatus className = "active" email={'orodriguez@icloud.com'}/>
                            </td>
                            <td>
                                18 MAY 2023 - 11:22 PM
                            </td>
                            <td>
                                <div className = "status-check success">
                                    <StatusComp status= "success"/>
                                </div>
                            </td>
                            <td>
                                <div className = "detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                08
                            </td>
                            <td>
                                <EmailStatus className = "active" email={'rgreen@aol.com'}/>
                            </td>
                            <td>
                                20 MAY 2023 - 12:12 PM
                            </td>
                            <td>
                                <div className = "status-check success">
                                    <StatusComp status= "success"/>
                                </div>
                            </td>
                            <td>
                                <div className = "detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                09
                            </td>
                            <td>
                                <EmailStatus className = "inactive" email={'nmoore@gmail.com'}/>
                            </td>
                            <td>
                                13 MAY 2023 - 02:18 PM
                            </td>
                            <td>
                                <div className = "status-check uncomplete">
                                    <StatusComp status= "uncomplete"/>
                                </div>
                            </td>
                            <td>
                                <div className = "detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default ListingTable;