
import React from "react";

import "./capture.css";

const PassportTest = ({testImg, MessageText}) => {
    return (
        <>
            <div className = "test-img">
                <img src = {testImg} alt = "img"/>
                <div className = "img-text">
                    {MessageText}
                </div>
            </div>
        </>
    )
}

export default PassportTest;