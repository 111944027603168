
import React from "react";
import {Link} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import UserFooter from "../../components/user-footer/user-footer";

import CompleteVerification from "../../images/user/complete.svg";

const VerifiedSuccess = () => {
    return (
        <section className = "main inner-page user-page">
            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "user-body">
                            
                            <div className = "inner-body">
                                <p>
                                    <img className = "complete-verification" src = {CompleteVerification} alt = "icon"/>
                                </p>
                                <h1>
                                    Successfully Verified
                                </h1>
                                <p>
                                    You will be redirected in 5 seconds
                                </p>

                                <p className = "mt-3 mt-lg-5">
                                    <Link to = "/user-verification" className = "btn btn-primary btn-gotit">
                                        Got it
                                    </Link>
                                </p>
                                
                            </div>
                        </div>
                        <UserFooter/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default VerifiedSuccess;