
import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import PageTitle from "../components/page-title/page-title";
import Input from "../components/input/input";
import Inputpassword from "../components/input/input-password";
import Exs_logo from "../images/account/exs-logo.png";

const MyAccount = () => {
    return (
        <section className = "main inner-page account-page">
            <Container fluid className = "mb-1 mb-md-4 px-2 px-sm-0">
                <Row>
                    <Col xs = {12} className = "px-0">
                        <PageTitle description={'My Account'}/>
                    </Col>
                </Row>
            </Container>

            <Container fluid  className = "px-2 px-sm-0">
                <Row className = "my-account-sec">
                    <Col md = {5} className = "px-0 d-none d-md-block">
                        <div className = "logo-side h-100">
                            <div className = "company-logo">
                                <img src = {Exs_logo} alt = "logo"/>
                            </div>
                        </div>
                    </Col>
                    <Col md = {7} className = "px-0">
                       <div className = "account-form-side sigup-login-form h-100">
                            <div className = "form-group mb-4">
                                <PageTitle description={'Personal Information'}/>
                            </div>
                            <Input type = {'text'} value = {'name'} label = 'Name'/>
                            <Input type = {'email'} value = {'email'} label = 'Email'/>
                            <Input type = {'text'} value = {'company'} label = 'Company Name'/>
                            <Inputpassword type = 'password' value = {'password'} label = 'Password'/>
                            <Inputpassword type = 'password' value = {'repeatpassword'} label = 'Repeat Password'/>
                            <div className = "form-group">
                                <Button type = "submit" variant = "primary">
                                    Save Change
                                </Button>
                            </div>
                       </div>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}

export default MyAccount;