
import React, {useState, useEffect, useRef} from 'react';
import { Link, useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from 'react-bootstrap/Button';

import Kyc_logo from "../../images/header/kyc-logo.svg";
import Language_iocn from "../../images/header/language-icon.svg";
import company_logo from "../../images/account/exs-logo.png";
import User_icon from "../../images/header/user.svg";
import Setting_icon from "../../images/header/setting.svg";
import Logout_icon from "../../images/header/logout.svg";
import Dropdown_icon from "../../images/header/menu-dp.svg"

import "./navbar.css";

const Navbarmain = ()=>{

    const[isActive, setIsActive] = useState(false);
    const buttonRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
          // Check if the click event occurred outside the button
            if (buttonRef.current && !buttonRef.current.contains(event.target)) {
                setIsActive(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener('click', handleOutsideClick);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleOutsideClick);
            };
        }, 
    []);

    const toggleButton = () => {
        setIsActive(!isActive);
    };

    const handleClick = () => {
        toggleButton();
    };

    const location = useLocation();
    const { pathname } = location;

    const showLanguageButton = ['/login', '/', '/forgot-password', '/reset-password', 
    '/user-verification', '/choose-user-doc', '/upload-document', '/scan-face', '/capture-document', '/complete-capture', '/upload-file', '/upload-verify', 'upload-success'].includes(pathname);
    const showUserDropdown = ['/my-account', '/company-dashboard', '/manage-api', '/create-api'].includes(pathname);
    const showAdminDropdown = ['/manage-company'].includes(pathname);

    return(

        <Navbar  fixed="top" className = "navbar-main">
            <Container fluid>
                <Navbar.Brand href="/" className = "border-0">
                    <img src = {Kyc_logo} alt = "logo"/>
                </Navbar.Brand>
                <Navbar>
                    <Nav className="ms-auto">
                    
                        {showUserDropdown && (
                            <div ref={buttonRef} className = {isActive ? 'active menu-dropdown' : 'menu-dropdown'} onClick={handleClick}>
                            <Button variant = "toggle-m">
                                    <div className = "logo-img">
                                        <img src = {company_logo} alt = "logo"/>
                                    </div>
                                    <span className = "company-name">
                                        EX Sports
                                    </span>
                                    <span className = "dp-icon">
                                        <img src = {Dropdown_icon} alt = "icon"/>
                                    </span>
                            </Button>
                            <div className = "dd-menu-list">
                                    <ul className = "nav flex-column">
                                        <li className = "nav-item">
                                            <Link to = "/my-account">
                                                <div className = "link-icon">
                                                    <img src = {User_icon} alt = "icon"/>
                                                </div>
                                                <span>My Account</span>
                                            </Link>
                                        </li>
                                        <li className = "nav-item">
                                            <Link to = "/manage-api">
                                                <div className = "link-icon">
                                                    <img src = {Setting_icon} alt = "icon"/>
                                                </div>
                                                <span>Manage API Keys</span>
                                            </Link>
                                        </li>
                                        <li className = "nav-item">
                                            <Link to = "/">
                                                <div className = "link-icon">
                                                    <img src = {Logout_icon} alt = "icon"/>
                                                </div>
                                                <span>Sign out</span>
                                            </Link>
                                        </li>
                                    </ul>
                            </div>
                            </div>
                        )}

                        {showAdminDropdown && (
                            <div ref={buttonRef} className = {isActive ? 'active menu-dropdown' : 'menu-dropdown'} onClick={handleClick}>
                            <Button variant = "toggle-m">
                                    <div className = "logo-img">
                                        <div className = "super-admin-icon">
                                            S
                                        </div>
                                    </div>
                                    <span className = "company-name">
                                        Supper Admin
                                    </span>
                                    <span className = "dp-icon">
                                        <img src = {Dropdown_icon} alt = "icon"/>
                                    </span>
                            </Button>
                            <div className = "dd-menu-list admin-dd-list">
                                    <ul className = "nav flex-column">
                                        <li className = "nav-item">
                                            <Link to = "/manage-company">
                                                <div className = "link-icon">
                                                    <img src = {Setting_icon} alt = "icon"/>
                                                </div>
                                                <span>Manage Company</span>
                                            </Link>
                                        </li>
                                        <li className = "nav-item">
                                            <Link to = "/">
                                                <div className = "link-icon">
                                                    <img src = {Logout_icon} alt = "icon"/>
                                                </div>
                                                <span>Sign out</span>
                                            </Link>
                                        </li>
                                    </ul>
                            </div>
                            </div>
                        )}

                        {showLanguageButton && <Button variant = "language">
                            <img src = {Language_iocn} alt = "logo"/>English
                        </Button>}

                    </Nav>
                </Navbar>
            </Container>
        </Navbar>
        
    );
}

export default Navbarmain;