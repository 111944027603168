
import Header from "./components/navbar/Navbar";


import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './css/kyc-style.css';
import './css/kyc-user-side-style.css';
import './css/kyc-admin-style.css';

import React,{ Fragment } from "react";
import {Route, Routes} from "react-router-dom"

import Login from "./pages/login";
import Signup from "./pages/signup";
import Forgotpassword from "./pages/forgot-password";
import Resetpassword from "./pages/reset-password";
import CompanyDashboard from "./pages/company-dashboard";
import MyAccount from "./pages/my-account";
import ManageApi from "./pages/manage-api";
import CreateApi from "./pages/create-api";
import UserVerification from "./pages/User/user-verification";
import ChooseDocment from "./pages/User/choose-user-doc";
import UploadDocument from "./pages/User/upload-document";
import CaptureStart from "./pages/User/capture-document";
import CompleteDoc from "./pages/User/complete-capture";
import ScaneFace from "./pages/User/scan-face";
import ManageCompany from "./pages/admin/manage-company";
import UploadFile from "./pages/User/upload-file";
import UploadVerify from "./pages/User/upload-verify";
import VerifiedSuccess from "./pages/User/upload-success";

function App() {
  return (
    <>
      <Fragment>
        <Header/>
        <Routes>
           <Route path= "/" element={<Signup/>}/>
            <Route path= "/login" element={<Login/>}/>
            <Route path= "/forgot-password" element={<Forgotpassword/>}/>
            <Route path= "/reset-password" element={<Resetpassword/>}/>
            <Route path= "/company-dashboard" element={<CompanyDashboard/>}/>
            <Route path= "/my-account" element={<MyAccount/>}/>
            <Route path= "/manage-api" element={<ManageApi/>}/>
            <Route path= "/create-api" element={<CreateApi/>}/>
            <Route path= "/user-verification" element={<UserVerification/>}/>
            <Route path= "/choose-user-doc" element={<ChooseDocment/>}/>
            <Route path= "/upload-document" element={<UploadDocument/>}/>
            <Route path= "/capture-document" element={<CaptureStart/>}/>
            <Route path= "/complete-capture" element={<CompleteDoc/>}/>
            <Route path= "/scan-face" element={<ScaneFace/>}/>
            <Route path= "/manage-company" element={<ManageCompany/>}/>
            <Route path= "/upload-file" element={<UploadFile/>}/>
            <Route path= "/upload-verify" element={<UploadVerify/>}/>
            <Route path= "/upload-success" element={<VerifiedSuccess/>}/>
        </Routes>
      </Fragment>
    </>
  );
}

export default App;
