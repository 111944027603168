
import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


import passport1 from "../../images/user/sample1.png";
import passport2 from "../../images/user/sample2.png";
import passport3 from "../../images/user/sample3.png";

import PassportTest from "../../components/capture-id/capture-test";
import CaptureComplete from "../../components/capture-id/capture-complete";

import UserFooter from "../../components/user-footer/user-footer";

const CompleteDoc = () => {

    return (
        <section className = "main inner-page user-page">
            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "user-body text-start upload-id-passport">
                            <ProgressBar now={50} />
                            <div className = "inner-body text-start capture-photo">
                                
                                <div className = "choose-doc">
                                   
                                   <Row>
                                        <Col lg = {6}>
                                            <h1>
                                                Capture Passport
                                            </h1>
                                            <p>
                                                Position it in the frame or  <span id = "upload">Upload a photo</span> a photo of the passport.
                                            </p>
                                            <label>
                                                Please ensure the photo you take:
                                            </label>
                                            <div className = "passport-show-img">
                                                <PassportTest testImg={passport1} MessageText={'Shows all details'}/>
                                                <PassportTest testImg={passport2} MessageText={'Isn’t covered by anything'}/>
                                                <PassportTest testImg={passport3} MessageText={'Is clear and focused'}/>
                                            </div>
                                        </Col>
                                        <Col lg = {6}>
                                            <div className = "Img-capture-section">
                                                <p>
                                                    Place the <b>Passport (Photo page)</b> within the capture window.
                                                </p>
                                                <CaptureComplete/>
                                            </div>
                                        </Col>
                                   </Row>
                                    
                                </div>

                            </div>
                        </div>
                        <UserFooter/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default CompleteDoc;