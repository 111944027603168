
import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { Link } from 'react-router-dom';

import './capture.css';

const CaptureComplete = () => {
    return (
        <>
            <div className = "camera-sec">
                <div className = "camera-body"></div>
            </div>
            
            <div className = "final-action">
                <Row>
                    <Col md = {7}>
                        <Link to = "/scan-face" className = "btn btn-primary">
                            Yes, Its Clear
                        </Link>
                    </Col>
                    <Col md = {5}>
                        <Button variant = "primary-outline">
                            Retake
                        </Button>
                    </Col>
                </Row>
            </div>

        </>
    )
}

export default CaptureComplete;