
import React from 'react';
// import { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import PageTitle from '../components/page-title/page-title';
import RepotCard from '../components/kyc-report/report-card';
import ListingTable from '../components/listing-table/listing-table';
import CustomSelect from '../components/select-box/selectbox';

// import Frame from '../images/dashboard/Frame.svg';
import Success from '../images/dashboard/KYC-success.svg';
import Uncompleted from '../images/dashboard/KYC-uncompleted.svg';
import Failed from '../images/dashboard/KYC-failed.svg';

const CompanyDashboard = () => {

    const options = [
        { label: 'Configuration 1', value: 1 },
        { label: 'Configuration 2', value: 2 },
        { label: 'Configuration 3', value: 3 },
      ];
      

        const handleSelect = (option) => {
          console.log('Selected option:', option);
        };

    return (
        <section className = "main inner-page">
            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        {/* <PageTitle description={'Showing Statistics for'}/> */}
                        <div className = "sort-db statistics-option">
                            <label>Showing Statistics for</label>
                            <CustomSelect options={options} onSelect={handleSelect} label = 'Configuration 1'/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs ={12}>
                        <div className = "kyc-report">
                            <Row className = "mx-0">
                                <Col md = {6} lg = {3}>
                                    <RepotCard Image={<><svg className = "pie-svg" height="35" width="35" viewBox="0 0 20 20">
                                        <circle r="10" cx="10" cy="10" fill="white" />
                                        <circle r="5" cx="10" cy="10" fill="transparent"
                                                stroke="#4080FF"
                                                stroke-width="10"
                                                stroke-dasharray="calc(35 * 31.4 / 100) 31.4"
                                                transform="rotate(-90) translate(-20)" />
                                        </svg></>} reportTitle={'KYC Consumed'} result={'150 / 1500'}/>
                                </Col>
                                <Col md = {6} lg = {3}>
                                    <RepotCard Image={<><img src = {Success} alt = "icon"/></>} reportTitle={'KYC Successful'} result={'150'}/>
                                </Col>
                                <Col md = {6} lg = {3}>
                                    <RepotCard Image={<><img src = {Uncompleted} alt = "icon"/></>} reportTitle={'KYC Uncompleted'} result={'150'}/>
                                </Col>
                                <Col md = {6} lg = {3}>
                                    <RepotCard Image={<><img src = {Failed} alt = "icon"/></>} reportTitle={'KYC Failed'} result={'19'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "kyc-table">
                            <ListingTable/>
                        </div>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}

export default CompanyDashboard;