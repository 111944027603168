
import React from "react";

// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";

import { Link } from 'react-router-dom';

import './capture.css';

const CaptureId = () => {
    return (
        <>
            <div className = "camera-sec">
                <div className = "camera-body"></div>
            </div>

            <Link to = "/capture-document" className = "btn btn-primary">
                Capture
            </Link>

        </>
    )
}

export default CaptureId;