
import React from "react";

import "./user-status.css";

const StatusComp = ({ status }) => {

    let statusText = '';

    if (status === 'success') {
        statusText = 'Success';
    } else if (status === 'failed') {
        statusText = 'Failed';
    } else if (status === 'uncomplete') {
        statusText = 'Uncomplete';
    }
    
    return (
        <div className={`status-check ${status}`}>
            <span className="status-icon"></span>
            <span className="status-title">{statusText}</span>
        </div>
    );
}

export default StatusComp;