
import React from "react";
import { Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from "react-bootstrap/Table";

import PageTitle from "../components/page-title/page-title";
import Accesskey from "../components/access-key/access-key";

const ManageApi = () => {

    return (
        <section className = "main inner-page manage-api">
            <Container fluid>
                <Row>
                    <Col xs = {12} className = "d-flex align-items-center justify-content-between">
                        <PageTitle description={'Manage API Keys'}/>
                        <Link to = "/create-api" className = "btn btn-primary">
                            Create API Key
                        </Link>
                    </Col>
                </Row>
            </Container>
            
            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "kyc-custom-table">
                        <Table responsive className = "kyc-data-table mb-0">
                            <thead>
                                <tr>
                                    <th>
                                        ACCESS KEY
                                    </th>
                                    <th>
                                        WEBSITE  URL
                                    </th>
                                    <th>
                                        REDIRECT URL
                                    </th>
                                    <th>
                                        action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Accesskey activationKey={'4433447897643225670'}/>
                                    </td>
                                    <td>https://bestwallet.io/kyc</td>
                                    <td>https://bestwallet.io/success</td>
                                    <td>
                                        <Link to = "#" className = "btn btn-primary-outline">
                                            Regenerate Key
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Accesskey activationKey={'4433447897643225670'}/>
                                    </td>
                                    <td>https://metagoat.co.uk/verify</td>
                                    <td>https://metagoat.co.uk/verify</td>
                                    <td>
                                        <Link to = "#" className = "btn btn-primary-outline">
                                            Regenerate Key
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Accesskey activationKey={'4433447897643225670'}/>
                                    </td>
                                    <td>https://streamshark.net</td>
                                    <td>https://streamshark.net</td>
                                    <td>
                                        <Link to = "#" className = "btn btn-primary-outline">
                                            Regenerate Key
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        </div>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}

export default ManageApi;