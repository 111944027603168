
import React, { useState, useMemo } from "react";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import ProgressBar from "react-bootstrap/ProgressBar";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


import IdCardicon from "../../images/user/card-icon.svg";

import UserFooter from "../../components/user-footer/user-footer";

const ChooseDocment = () => {

    // const options = [
    //     { value: 'PAK', label: 'Pakistan' },
    //     { value: 'IND', label: 'India' },
    //     { value: 'BAN', label: 'Bangladash' },
    // ];

    // const [value, setValue] = useState('')
    const [selectedCountry, setSelectedCountry] = useState(null);

    const options = useMemo(() => countryList().getData(), [])

    // const changeHandler = (value) => {
    //     setValue(value);
    // }

    const changeHandler = (selectedOption) => {
        setSelectedCountry(selectedOption);
    }

    return (
        <section className = "main inner-page user-page">
            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "user-body text-start">
                            <ProgressBar now={30} />
                            <div className = "inner-body text-start">
                                <h1 className = "text-center">
                                    Choose your document
                                </h1>
                                
                                <div className = "choose-doc">
                                    <div className = "select-country">
                                        <label>Issueing country*</label>
                                        <Select options={options} defaultValue={selectedCountry} placeholder = {'Select Country'} onChange={changeHandler}/>
                                    </div>
                                    {selectedCountry && ( 
                                        <div className = "doc-sec">
                                            <label>
                                                Choose which ID you’d like to provide
                                            </label>
                                            <Link to = "/upload-document" class = "select-doc-type">
                                                <div className = "doc-icon">
                                                    <img src = {IdCardicon} alt = "icon"/>
                                                </div>
                                                <div className = "doc-name">
                                                    <div className = "title">Passport</div>
                                                    <div className = "type">Photo page</div>
                                                </div>
                                            </Link>
                                            <Link to = "/upload-document" class = "select-doc-type">
                                                <div className = "doc-icon">
                                                    <img src = {IdCardicon} alt = "icon"/>
                                                </div>
                                                <div className = "doc-name">
                                                    <div className = "title">National ID Card</div>
                                                    <div className = "type">Front and back</div>
                                                </div>
                                            </Link>
                                        </div>
                                     )} 
                                </div>

                            </div>
                        </div>
                        <UserFooter/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ChooseDocment;