
import React, { useState, useEffect } from 'react';

import "./input.css";
import Refreshicon from "../../images/manage-api/refresh-icon.svg";

const InputAccesskey = ({label,type,placeholder, currentvalue}) => {

    const [isActive, setIsActive] = useState(false);
    const handleClick = () => {
        setIsActive(true);
        setTimeout(() => {
        setIsActive(false);
        }, 1000);
    };

    useEffect(() => {
        if (isActive) {
        setTimeout(() => {
            setIsActive(false);
        }, 1000);
        }
    }, [isActive]);

    return(
        <>
            <div className = "form-group form-floating access-key-input">
                <input type = {type} className = "form-control" placeholder = {placeholder} value = {currentvalue}/>
                <label>{label}</label>
                <span className={isActive ? 'active refresh-btn' : 'refresh-btn'} onClick={handleClick}>
                    <img src = {Refreshicon} alt = "refresh-icon"/>
                </span>
            </div>
        </>
    );
}

export default InputAccesskey;