
import React from "react";
import { useState } from  "react";

import Table from "react-bootstrap/Table";
import Row from 'react-bootstrap/Row';
import Collapse from 'react-bootstrap/Collapse';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import RepotCard from "../../../components/kyc-report/report-card";
import StatusComp from "../../../components/user-status/user-status";
import StatusSelect from "./status-dropdown";
import EmailStatus from "../../../components/user-status/email-status";

import detailBg from "../../../images/dashboard/detail-db-icon.svg";
import Success from '../../../images/dashboard/KYC-success.svg';
import Uncompleted from '../../../images/dashboard/KYC-uncompleted.svg';
import Failed from '../../../images/dashboard/KYC-failed.svg';


const CompanyTable = () => {

    const [openRows, setOpenRows] = useState(-1);

    const toggleCollapse = (rowIndex) => {
        if (openRows === rowIndex) {
        setOpenRows(-1); // Collapse the current row if it's clicked again
        } else {
        setOpenRows(rowIndex); // Open the clicked row
        }
    }

    const options = [
        { label: 'Status-Active', value: 'active' },
        { label: 'Status-Inactive', value: 'inactive' },
    ];
      

    const handleSelect = (option) => {
        console.log('Selected option:', option);
    };

    return (
            <div className = "kyc-custom-table">
                <Table responsive className = "kyc-data-table mb-0">
                    <thead>
                        <tr>
                            <th>
                                ID
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                Email
                            </th>
                            <th>
                                Created AT
                            </th>
                            <th>
                                License Expiry
                            </th>
                            <th>
                                KYC USAGE
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                Details
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={openRows === 0 ? 'active' : ''}  onClick={() => toggleCollapse(0)} aria-controls="detail1" aria-expanded={openRows === 0}>
                            <td>
                                01
                            </td>
                            <td>
                                <EmailStatus className = "active" email={'Pfannerstill and Kertzmann'}/>
                            </td>
                            <td>
                                uwhite@aol.com
                            </td>
                            <td>
                                30 MAY 2023
                            </td>
                            <td>
                                30 MAY 2023
                            </td>
                            <td>
                                300 / 1000
                            </td>
                            <td>
                                <div className = "status-check success">
                                    <StatusComp status= "success"/>
                                </div>
                            </td>
                            <td>
                                <div className="detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            
                            <td className = "hidden-row py-0" colSpan={8}>
                                <Collapse in={openRows === 0}>
                                    <div id="detail1">
                                        <Row className = "py-3">
                                            <Col xs ={12}>
                                                <div className = "kyc-report manage-company-report">
                                                    <Row className = "mx-0 justify-content-between align-items-center">
                                                        <Col>
                                                            <RepotCard Image={<><svg className = "pie-svg" height="35" width="35" viewBox="0 0 20 20">
                                                                <circle r="10" cx="10" cy="10" fill="white" />
                                                                <circle r="5" cx="10" cy="10" fill="transparent"
                                                                        stroke="#4080FF"
                                                                        stroke-width="10"
                                                                        stroke-dasharray="calc(35 * 31.4 / 100) 31.4"
                                                                        transform="rotate(-90) translate(-20)" />
                                                                </svg></>} reportTitle={'KYC Consumed'} result={'150 / 1500'}/>
                                                        </Col>
                                                        <Col>
                                                            <RepotCard Image={<><img src = {Success} alt = "icon"/></>} reportTitle={'KYC Successful'} result={'150'}/>
                                                        </Col>
                                                        <Col>
                                                            <RepotCard Image={<><img src = {Uncompleted} alt = "icon"/></>} reportTitle={'KYC Uncompleted'} result={'150'}/>
                                                        </Col>
                                                        <Col>
                                                            <RepotCard Image={<><img src = {Failed} alt = "icon"/></>} reportTitle={'KYC Failed'} result={'19'}/>
                                                        </Col>
                                                        <Col>
                                                            <div className = "status-control py-3">
                                                                <StatusSelect options={options} onSelect={handleSelect} label = 'Status -Active'/>
                                                                <Button variant = "renew-package">
                                                                    Renew Package
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Collapse>
                            </td>
                            
                        </tr>
                        <tr className={openRows === 1 ? 'active' : ''} onClick={() => toggleCollapse(1)} aria-controls="detail2" aria-expanded={openRows === 1}>
                            <td>
                                02
                            </td>
                            <td>
                                <EmailStatus className = "failed" email={'Rolfson Group'}/>
                            </td>
                            <td>
                                lmoore@yahoo.com
                            </td>
                            <td>
                                15 MAY 2023
                            </td>
                            <td>
                                15 MAY 2023
                            </td>
                            <td>
                                230 / 1000
                            </td>
                            <td>
                                <div className = "status-check failed">
                                    <StatusComp status= "failed"/>
                                </div>
                            </td>
                            <td>
                                <div className="detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            
                        <td className = "hidden-row py-0" colSpan={8}>
                                <Collapse in={openRows === 1}>
                                    <div id="detail2">
                                        <Row className = "py-3">
                                            <Col xs ={12}>
                                                <div className = "kyc-report manage-company-report">
                                                    <Row className = "mx-0 justify-content-between align-items-center">
                                                        <Col>
                                                            <RepotCard Image={<><svg className = "pie-svg" height="35" width="35" viewBox="0 0 20 20">
                                                                <circle r="10" cx="10" cy="10" fill="white" />
                                                                <circle r="5" cx="10" cy="10" fill="transparent"
                                                                        stroke="#4080FF"
                                                                        stroke-width="10"
                                                                        stroke-dasharray="calc(35 * 31.4 / 100) 31.4"
                                                                        transform="rotate(-90) translate(-20)" />
                                                                </svg></>} reportTitle={'KYC Consumed'} result={'150 / 1500'}/>
                                                        </Col>
                                                        <Col>
                                                            <RepotCard Image={<><img src = {Success} alt = "icon"/></>} reportTitle={'KYC Successful'} result={'150'}/>
                                                        </Col>
                                                        <Col>
                                                            <RepotCard Image={<><img src = {Uncompleted} alt = "icon"/></>} reportTitle={'KYC Uncompleted'} result={'150'}/>
                                                        </Col>
                                                        <Col>
                                                            <RepotCard Image={<><img src = {Failed} alt = "icon"/></>} reportTitle={'KYC Failed'} result={'19'}/>
                                                        </Col>
                                                        <Col>
                                                            <div className = "status-control py-3">
                                                                <StatusSelect options={options} onSelect={handleSelect} label = 'Status -Active'/>
                                                                <Button variant = "renew-package">
                                                                    Renew Package
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Collapse>
                            </td>
                            
                        </tr>
                        <tr>
                            <td>
                                03
                            </td>
                            <td>
                                <EmailStatus className = "active" email={'Ernser and Sons'}/>
                            </td>
                            <td>
                                pnguyen@gmail.com
                            </td>
                            <td>
                                11 MAY 2023
                            </td>
                            <td>
                                11 MAY 2023
                            </td>
                            <td>
                                230 / 1000
                            </td>
                            <td>
                                <div className = "status-check success">
                                    <StatusComp status= "success"/>
                                </div>
                            </td>
                            <td>
                                <div className = "detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                04
                            </td>
                            <td>
                                <EmailStatus className = "inactive" email={'Hirthe, Howe and Cartwright'}/>
                            </td>
                            <td>
                                egarcia@icloud.com
                            </td>
                            <td>
                                12 MAY 2023
                            </td>
                            <td>
                                12 MAY 2023
                            </td>
                            <td>
                                230 / 1000
                            </td>
                            <td>
                                <div className = "status-check uncomplete">
                                    <StatusComp status= "uncomplete"/>
                                </div>
                            </td>
                            <td>
                                <div className = "detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                05
                            </td>
                            <td>
                                <EmailStatus className = "active" email={'Ullrich - Farrell'}/>
                            </td>
                            <td>
                                mtorres@icloud.com
                            </td>
                            <td>
                                22 MAY 2023
                            </td>
                            <td>
                                22 MAY 2023
                            </td>
                            <td>
                                230 / 1000
                            </td>
                            <td>
                                <div className = "status-check success">
                                    <StatusComp status= "success"/>
                                </div>
                            </td>
                            <td>
                                <div className = "detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                06
                            </td>
                            <td>
                                <EmailStatus className = "failed" email={'Ankunding and Sons'}/>
                            </td>
                            <td>
                                ghall@outlook.com
                            </td>
                            <td>
                                24 MAY 2023
                            </td>
                            <td>
                                24 MAY 2023
                            </td>
                            <td>
                                230 / 1000
                            </td>
                            <td>
                                <div className = "status-check failed">
                                    <StatusComp status= "failed"/>
                                </div>
                            </td>
                            <td>
                                <div className = "detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                07
                            </td>
                            <td>
                                <EmailStatus className = "active" email={'Hessel LLC'}/>
                            </td>
                            <td>
                                bgeorge@aol.com
                            </td>
                            <td>
                                18 MAY 2023
                            </td>
                            <td>
                                18 MAY 2023
                            </td>
                            <td>
                                230 / 1000
                            </td>
                            <td>
                                <div className = "status-check success">
                                    <StatusComp status= "success"/>
                                </div>
                            </td>
                            <td>
                                <div className = "detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                08
                            </td>
                            <td>
                                <EmailStatus className = "active" email={'Tillman Inc'}/>
                            </td>
                            <td>
                            vsanchez@outlook.com
                            </td>
                            <td>
                                20 MAY 2023
                            </td>
                            <td>
                                20 MAY 2023
                            </td>
                            <td>
                                230 / 1000
                            </td>
                            <td>
                                <div className = "status-check success">
                                    <StatusComp status= "success"/>
                                </div>
                            </td>
                            <td>
                                <div className = "detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                09
                            </td>
                            <td>
                                <EmailStatus className = "inactive" email={'Halvorson Inc'}/>
                            </td>
                            <td>
                                nmitchell@gmail.com
                            </td>
                            <td>
                                13 MAY 2023
                            </td>
                            <td>
                                13 MAY 2023
                            </td>
                            <td>
                                30 / 1000
                            </td>
                            <td>
                                <div className = "status-check uncomplete">
                                    <StatusComp status= "uncomplete"/>
                                </div>
                            </td>
                            <td>
                                <div className = "detail-btn">
                                    <img src = {detailBg} alt = "db-icon"/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
    )
}


export default CompanyTable;