

import React from "react";

import {Link} from "react-router-dom";

import "./face-scanner.css";

const FaceScanner = () => {
    return (
        <div className = "scan-face-section">
            <div className = "face-scanner">

            </div>
            <Link to = "/upload-verify" className = "btn btn-primary">
                I’m Ready
            </Link>
        </div>
    )
}

export default FaceScanner;