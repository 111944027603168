import React, { useState, useRef, useEffect } from 'react';

import "./selectstatus.css";
import dropdownIcon from "./Vector.svg";
import dropdownIcon_inactive from "./vactor-inactive.svg";

const StatusSelect = ({ options, onSelect, label }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedOption && selectedOption.value === "inactive") {
      selectRef.current.classList.add("inactive");
    } else {
      selectRef.current.classList.remove("inactive");
    }
  }, [selectedOption]);

  return (
    <div ref={selectRef} className={`custom-select status-select mb-2 ${isOpen ? 'open' : ''}`}>
      <div className="select-header" onClick={handleToggle}>
        <span className="selected-option">
          {selectedOption ? selectedOption.label : label}
        </span>
        <span className="dropdown-icon">
          <img src={dropdownIcon} alt="icon" />
        </span>
        <span className="dropdown-icon inactive">
          <img src={dropdownIcon_inactive} alt="icon" />
        </span>
      </div>
      {isOpen && (
        <ul className="options-list">
          {options.map((option) => (
            <li
              key={option.value}
              className={`option ${selectedOption === option ? 'selected' : ''}`}
              onClick={() => handleSelectOption(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default StatusSelect;