
import React from "react";

import "./user-details.css";

const UserHeader = ({titleHeading, spantext}) => {
    return (
        <>
            <div className = "detail-title">
                {titleHeading} <span>{spantext}</span>
            </div>
        </>
    )
}

export default UserHeader;