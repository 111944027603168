
import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";

import UserFooter from "../../components/user-footer/user-footer";
import FaceScanner from "../../components/scan-face/face-scanner";

const ScaneFace = () => {
    return (
        <section className = "main inner-page user-page">
            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "user-body">
                            <ProgressBar now={50} />
                            <div className = "inner-body">
                                <h1>
                                    Scan Your Face
                                </h1>
                                <p>
                                    Please frame your face in the Oval
                                </p>
                                
                                <FaceScanner/>
                                
                            </div>
                        </div>
                        <UserFooter/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ScaneFace;