
import React from "react";

// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { Link } from 'react-router-dom';

import './capture.css';

const CaptureTime = () => {
    return (
        <>
            <div className = "camera-sec">
                <div className = "camera-body"></div>
            </div>

           
            <div className = "capture-timer mb-3">
                <ul className = "nav justify-content-center">
                    <li className = "nav-item">
                        <Button variant = "default" className = "active">
                            1
                        </Button>
                    </li>
                    <li className = "nav-item">
                        <Button variant = "default">
                            2
                        </Button>
                    </li>
                    <li className = "nav-item">
                        <Button variant = "default">
                            3
                        </Button>
                    </li>
                    <li className = "nav-item">
                        <Link to = "/complete-capture" type = "button" className = "btn btn-default">
                            <i className ="bi bi-camera"></i>
                        </Link>
                    </li>
                </ul>
            </div>

        </>
    )
}

export default CaptureTime;