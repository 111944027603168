
import React, {useState} from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';

import PageTitle from "../../components/page-title/page-title";
import CompanyTable from "./admin-component/company-table";

import Input from "../../components/input/input";

const ManageCompany = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const options = [
        { value: 'PAK', label: 'Pakistan' },
        { value: 'IND', label: 'India' },
        { value: 'BAN', label: 'Bangladash' },
    ];

    const [selectedOption, setSelectedOption] = useState(null);

    return (
        <section className = "main inner-page admin-section">

            <Container fluid>
                <Row>
                    <Col xs = {12} className = "d-flex align-items-center justify-content-between">
                        <PageTitle description={'Manage Companies'}/>
                        <Button variant = "primary" onClick={handleShow}>
                            Add Company
                        </Button>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "kyc-table">
                            <CompanyTable/>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} centered className = "create-api-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Add Company</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <Form className = "sigup-login-form create-api-form">
                            <Input type = {'text'} value = {'Name'} label = 'Name'/>
                            <Input type = {'email'} value = {'Email'} label = 'Email'/>
                            <Input type = {'text'} value = {'Company Name'} label = 'Company Name'/>
                            <div className = "select-country">
                                <Select className = "mb-3" defaultValue={selectedOption} placeholder = {'License'} onChange={setSelectedOption} options={options}/>
                            </div>
                            <div className = "form-group mb-0">
                                <Button variant = "primary" onClick={handleClose}>
                                    Register
                                </Button>
                            </div>
                        </Form>
                    </Modal.Body>
            </Modal>

        </section>
    )
}

export default ManageCompany;