
import React from 'react';

import "./input.css";

const Input = ({label,type,value, onChange}) => {

    return(
        <>
            <div className = "form-group form-floating">
                <input type = {type} className = "form-control" placeholder = {value} onChange={onChange}/>
                <label>{label}</label>
            </div>
        </>
    );
}

export default Input;