
import React from "react";
import { Link } from 'react-router-dom';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ClockIcon from "../../images/user/time-icon.svg";
import IdCardicon from "../../images/user/card-icon.svg";
import Ageicon from "../../images/user/18+icon.svg";

import VerificationStep from "../../components/user-verification-steps/verification-step";
import UserFooter from "../../components/user-footer/user-footer";

const UserVerification = () => {
    return (
        <section className = "main inner-page user-page">
            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "user-body">
                            <div className = "inner-body">
                                <h1>
                                    Before we start Verification
                                </h1>
                                <p>
                                    To speed up your account opening process, please provide accurate personal 
                                    information for identity verification.
                                </p>
                                <h2>
                                    You will need the following:
                                </h2>
                                <div className = "verification-steps">
                                    
                                    <VerificationStep Stepicon={ClockIcon} StepMessage={'5 mins of your time to complete this application'}/>

                                    <div className = "step-space">
                                        <div className = "space-line"></div>
                                    </div>

                                    <VerificationStep Stepicon={IdCardicon} StepMessage={`could be a driver's licene or passport`}/>

                                    <div className = "step-space">
                                        <div className = "space-line"></div>
                                    </div>

                                    <VerificationStep Stepicon={Ageicon} StepMessage={'You must be over 18 years of age'}/>
                                </div>
                                <Link to = "/choose-user-doc" className = "btn btn-primary">
                                    Start Verification
                                </Link>
                            </div>
                        </div>
                       <UserFooter/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default UserVerification;