
import React, {useState} from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import PageTitle from "../components/page-title/page-title";
import Input from "../components/input/input";
import InputAccesskey from "../components/input/accesskey-input";
import CreateIcon from "../images/manage-api/create-icon.svg";

const CreateApi = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <section className = "main inner-page manage-api">
            <Container fluid>
                <Row>
                    <Col xs = {12} className = "d-flex align-items-center justify-content-between">
                        <PageTitle description={'Manage API Keys'}/>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "create-api">
                            <div className = "create-icon">
                                <img src = {CreateIcon} alt = "icon"/>
                            </div>
                            <div className = "title">
                                Create Your First API Key
                            </div>
                            <Button variant = "primary" onClick={handleShow}>
                                Create API Key
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} centered className = "create-api-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Create API Key</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <Form className = "sigup-login-form create-api-form">
                            <Input type = {'text'} value = {'Project Name'} label = 'Project Name'/>
                            <InputAccesskey type = {'text'} placeholder = {'Access key'} currentvalue={'4433447897643225670'} label = 'Access Key' />
                            <Input type = {'text'} value = {'Website URL'} label = 'Website URL'/>
                            <Input type = {'text'} value = {'Website URL'} label = 'Website URL'/>
                            <div className = "form-group mb-0">
                                <Button variant = "primary" onClick={handleClose}>
                                    Create 
                                </Button>
                            </div>
                        </Form>
                    </Modal.Body>
            </Modal>

        </section>
    )
}

export default CreateApi;