
import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import UserFooter from "../../components/user-footer/user-footer";

import VerifyIcon from "../../images/user/verify-animation.webp";

const UploadVerify = () => {
    return (
        <section className = "main inner-page user-page">
            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "user-body">
                            
                            <div className = "inner-body">
                                <h1>
                                    Verifying..
                                </h1>
                                <p>
                                    Please wait while we are verifying your information
                                </p>

                                <p>
                                    <img className = "verify-icon" src = {VerifyIcon} alt = "verify"/>
                                </p>
                                
                            </div>
                        </div>
                        <UserFooter/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default UploadVerify;