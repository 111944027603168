import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';

import Inputpassword from '../components/input/input-password';

const Resetpassword = ()=>{

    const [password, setPassword] = useState('');
    const [repeatpassword, setRepeatpassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        validatePasswords(event.target.value, repeatpassword);
    };

    const handleRepeatpasswordChange = (event) => {
        setRepeatpassword(event.target.value);
        validatePasswords(password, event.target.value);
    }

    const validatePasswords = (password, repeatPassword) => {
        if (password !== repeatPassword) {
            setErrorMessage("Passwords do not match");
        } else {
            setErrorMessage("");
        }
    };

    const isFormValid = password !== '' && 
    repeatpassword !== '' && 
    password === repeatpassword &&
    errorMessage === '';

    return(

        <section className = "main form-main">
            <Container>
                <Row>
                    <Col xs = "12">

                    <div className = "signup-content position-relative d-flex align-items-center justify-content-center">

                            <div className = "sigup-login-form login-form">
                                <h1>
                                    Reset your password
                                </h1>

                                <Inputpassword type = 'password' value = {'password'} label = 'Password' onChange={handlePasswordChange}/>

                                <Inputpassword type = 'password' value = {'repeatpassword'} label = 'Repeat Password' onChange={handleRepeatpasswordChange}/>

                               {errorMessage && <p className="error-message mb-2 text-danger">{errorMessage}</p>}

                                <div className = "form-group">
                                    <Button type = "submit" variant = "primary" disabled={!isFormValid}>
                                        Update
                                    </Button>
                                </div>

                            </div>
                    </div>

                    </Col>
                </Row>
            </Container>
        </section>
        
    );
}

export default Resetpassword;
